const MobileContentMenu = () =>
{

    const mobileContentMenu = document.getElementById('js-toggle-content');

    if (mobileContentMenu) {
        mobileContentMenu.addEventListener('click',function(e) {
            e.preventDefault();

            const menuContentHolder = document.getElementById('p-sidebar-holder');

            if ( menuContentHolder.classList.contains('visible') ) {
                menuContentHolder.classList.remove('visible');
                mobileContentMenu.innerHTML = '<span class="uppercase c-darkblue">Innehåll</span>';
            } else {
                menuContentHolder.classList.add('visible');
                mobileContentMenu.innerHTML = '<span class="uppercase c-darkblue">Stäng</span>';
            }
        });
    }
}

export default MobileContentMenu;