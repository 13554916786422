const DetectScroll = () =>
{

    var blocks = document.querySelectorAll('.scroll-section');

    window.addEventListener('scroll', () => {
       for(let i = 0; i < blocks.length; i++) {
          if (isInViewport(blocks[i])) {
             //console.log(blocks[i]);
             if ( document.querySelector( '[ href="#' + blocks[i].getAttribute('id') + '" ]' ) ) {
                //console.log(blocks[i].getAttribute('id'));
                var test = document.querySelector( '[ href="#' + blocks[i].getAttribute('id') + '" ]' );
                test.classList.add('active');
             }
             //console.log(blocks[i]);
          } else {
             if ( document.querySelector( '[ href="#' + blocks[i].getAttribute('id') + '" ]' ) ) {
                var test = document.querySelector( '[ href="#' + blocks[i].getAttribute('id') + '" ]' );
                test.classList.remove('active');
             }
          }
       };
    }, false)

    var isInViewport = function (elem) {
        var bounding = elem.getBoundingClientRect();
        //console.log(bounding.bottom);
        return (
            bounding.top <= 122 &&
            bounding.bottom >= -200
        );
    };
}

export default DetectScroll;