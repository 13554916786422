import $ from 'jquery';

import MobileAndScroll from './menuAndScroll';
import MobileContentMenu from './mobileContentMenu';
import DetectScroll from './detectScroll';

$(document).ready(function () {
    MobileAndScroll();
    MobileContentMenu();
    DetectScroll();

    var pressWrapper = $('.press-wrapper');
    var pressElements = $('.js-press-element');
    var activeClasses = [];

    $('.js-filter-btn').on('click touchstart', function(e) {
        e.preventDefault();

        var _this = $(this);
        var category = _this.data('value');

        if ( _this.hasClass('active') ) {
            _this.removeClass('active');
            //activeClasses.splice( $.inArray(category, activeClasses), 1 );
            activeClasses = [];
        } else {
            $('.cat-btn').removeClass('active');
            _this.addClass('active');
            //activeClasses.push(category);
            activeClasses = [category];
        }

        if ( _this.hasClass('js-filter-all') ) {
            activeClasses = [];
            $('.cat-btn').removeClass('active');
        }

        pressElements.fadeOut(10, function() {
            //$('.cat-4').fadeIn();
            if ( activeClasses.length > 0 ) {
                $('.js-filter-all').removeClass('active');

                for ( var i = 0; i < activeClasses.length; i++ )
                {
                    $('.cat-' + activeClasses[i]).fadeIn(200);
                }
            } else {
                $('.js-filter-all').addClass('active');
                
                pressElements.fadeIn(200);
            }
        });
    });

    $('.js-scroll-top').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        return false;
    });

    var sideBarMenu = $('#p-sidebar-holder');

    if ( sideBarMenu.length > 0 ) {
        var dFromTop = sideBarMenu.offset().top;

        $(window).scroll(function() {
            /*if ($(this).scrollTop() > 400) { // this refers to window
                $('.js-scroll-top').addClass('is-visible');
            } else {
                $('.js-scroll-top').removeClass('is-visible');
            }*/

            if ( $(this).scrollTop() > dFromTop ) {
                $('#p-sidebar-holder').addClass('is-fixed');
            } else {
                $('#p-sidebar-holder').removeClass('is-fixed');
            }

            /*if($(window).scrollTop() + $(window).height() == $(document).height()) {
                $('.js-scroll-top').addClass('is-bottom');
            } else {
                $('.js-scroll-top').removeClass('is-bottom');
            }*/
        });
    }

    $(window).scroll(function() {
        if ($(this).scrollTop() > 400) { // this refers to window
            $('.js-scroll-top').addClass('is-visible');
        } else {
            $('.js-scroll-top').removeClass('is-visible');
        }

        if($(window).scrollTop() + $(window).height() == $(document).height()) {
            $('.js-scroll-top').addClass('is-bottom');
        } else {
            $('.js-scroll-top').removeClass('is-bottom');
        }
    });
});

