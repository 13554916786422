const MobileAndScroll = () =>
{
    // Grab all the scroll class anchor elements, use whatever class you like
    const scrollElems = document.querySelectorAll('.js-scroll-elem');
    // Now add an event listeners to those element
    for(let i = 0; i < scrollElems.length; i++){
         const elem = scrollElems[i];
     
         elem.addEventListener('click',function(e) {
            e.preventDefault();
            // 1. Get the element id to which you want to scroll
            const scrollElemId = e.currentTarget.href.split('#')[1];
            
            // 2. find that node from the document
            const scrollEndElem = document.getElementById(scrollElemId);
            
            // 3. and well animate to that node.. 
            const anim = requestAnimationFrame((timestamp) => {
                const stamp = timestamp || new Date().getTime();
                const duration = 1200;
                const start = stamp;
         
                const startScrollOffset = window.pageYOffset;
                const scrollEndElemTop = scrollEndElem.getBoundingClientRect().top - 92;
         
                scrollToElem(start, stamp, duration, scrollEndElemTop, startScrollOffset);
            })
        })
    }

    // Lets ignore it for the moment.
    const easeInCubic = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
     
    const scrollToElem = (startTime, currentTime, duration, scrollEndElemTop, startScrollOffset) => {
         const runtime = currentTime - startTime;
         let progress = runtime / duration;
         
         progress = Math.min(progress, 1);
         
         const ease = easeInCubic(progress);
         
            window.scroll(0, startScrollOffset + (scrollEndElemTop * ease));
        if(runtime < duration){
                 requestAnimationFrame((timestamp) => {
                        const currentTime = timestamp || new Date().getTime();
                        scrollToElem(startTime, currentTime, duration, scrollEndElemTop, startScrollOffset);
                })
         }
    }

    const mobileMenu = document.getElementById('mobile-menu');

    mobileMenu.addEventListener('click',function(e) {
            e.preventDefault();

            const menuHolder = document.getElementById('menu-holder');

            if ( menuHolder.classList.contains('visible') ) {
                    menuHolder.classList.remove('visible');
                    mobileMenu.classList.remove('active');
            } else {
                    menuHolder.classList.add('visible');
                    mobileMenu.classList.add('active');
            }
    });
}

export default MobileAndScroll;